import { numberToFixed2 } from '@shared/lib/numbers';
import { IsDefined } from '@utils/js-ts';
import PROFILES from './mocks';
function getRandomNumberWithTwoDecimals(min, max) {
    const random = Math.random() * (max - min) + min;
    return parseFloat(random.toFixed(2));
}
const getCopiersData = () => {
    const copiers = [];
    for (let i = 0; i < PROFILES.length; i += 1) {
        const profileData = PROFILES[i];
        const username = i % 3 === 0 ? '' : profileData.username;
        const nickname = i % 3 === 0 ? '' : profileData.nickname;
        const avatar = (i % 2 === 0) || (i % 3 === 0) ? '' : '/avatars/Avatar.png';
        const profile = {
            id: i + 1,
            avatar,
            username,
            nickname,
            user_id: 1,
            type: 'CLIENT',
            location: profileData.location,
            joined_since: profileData.joined_since,
            description: profileData.description,
        };
        const portfolioSubscription = {
            id: i + 1,
            profile_id: profile.id,
            profile,
            queue_position: i + 1,
            past_month_difference: getRandomNumberWithTwoDecimals(-150.00, 150.00),
            amount: 830,
            bid: 15 + (PROFILES.length - 1 - i) * 5,
            position: i + 1,
            stop_loss: 15,
            delay: 0.01 * (i + 1),
            projected_income: 90 - (i * 0.3),
            manual_intervention: (i % 5 === 0),
        };
        copiers.push(portfolioSubscription);
    }
    return copiers;
};
export const getCopiers = (skip, take, sortByDelay = 'asc') => {
    let copiers = getCopiersData();
    const count = copiers.length;
    if (sortByDelay === 'asc') {
        copiers = copiers.sort((a, b) => a.delay - b.delay);
    }
    else if (sortByDelay === 'desc') {
        copiers = copiers.sort((a, b) => b.delay - a.delay);
    }
    if (IsDefined(skip) && IsDefined(take)) {
        copiers = copiers.slice(Number(skip), Number(skip) + Number(take));
    }
    return {
        count,
        data: copiers,
    };
};
export const getCopiersQueueDetails = () => {
    const copiers = getCopiersData();
    const first = copiers[0];
    const last = copiers[copiers.length - 1];
    const response = {
        first,
        last,
        copiers_count: copiers.length,
        delay_step: 0.01,
        avg_price_per_position: '',
        projected_income: {
            total: 0,
            step: 0,
        },
    };
    if (IsDefined(first)) {
        response.avg_price_per_position = numberToFixed2(first.bid / copiers.length);
    }
    if (IsDefined(first) && IsDefined(last)) {
        response.projected_income = {
            total: 90.0,
            step: 0.3,
        };
    }
    return response;
};
